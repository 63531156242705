/* global $ */

class App
{
    constructor()
    {
        this.menuMoving = false;
        this.disableLogger();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initFancybox();
        this.initStickyMenu();
        $('body').css('opacity', 1);
        console.log('App initialized');
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initForms()
    {
        if ($('form.js-validate').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(form => {
                $.each($('form.js-validate'), (i, elem) => {
                    new form.Form(elem.id);
                });
            });
        }
    }

    initToasts(elems)
    {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems)
    {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    initFancybox()
    {
        if ($('.fancy-box').length) {
            import(/* webpackChunkName: "fancybox" */ './components/fancybox');
        }
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 250) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });
    }
}

export default App;